import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-machine-learning-home2',
  templateUrl: './machine-learning-home2.component.html',
  styleUrls: ['./machine-learning-home2.component.css']
})
export class MachineLearningHome2Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
