import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OdometerModule } from 'ng2-odometer';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CarouselModule } from 'ngx-owl-carousel-o';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgwWowModule } from 'ngx-wow';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { IconsModule } from './icons/icons.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { FunfactComponent } from './funfact/funfact.component';
import { WorksComponent } from './home/works/works.component';
import { FeedbackComponent } from './home/feedback/feedback.component';
import { PartnerComponent } from './partner/partner.component';
import { AboutComponent } from './about/about.component';
import { GetStartedComponent } from './get-started/get-started.component';
import { FeaturesComponent } from './features/features.component';
import { FeaturesDetailsComponent } from './features-details/features-details.component';
import { ProjectComponent } from './project/project.component';
import { ProjectDetailsComponent } from './project-details/project-details.component';
import { ServicesComponent } from './services/services.component';
import { ServicesDetailsComponent } from './services-details/services-details.component';
import { HomeTeamComponent } from './home/home-team/home-team.component';
import { BlogComponent } from './blog/blog.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { RefundComponent } from './refund/refund.component';
import { FaqComponent } from './faq/faq.component';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { DevelopersComponent } from './developers/developers.component';
import { WebHostingComponent } from './web-hosting/web-hosting.component';
import { CustomerFeedbackComponent } from './web-hosting/customer-feedback/customer-feedback.component';
import { RepairComponent } from './repair/repair.component';
import { RepairPartnerComponent } from './repair/repair-partner/repair-partner.component';
import { IotComponent } from './iot/iot.component';
import { MachineLearningComponent } from './machine-learning/machine-learning.component';
import { MachineLearningHome2Component } from './machine-learning-home2/machine-learning-home2.component';
import { SupportComponent } from './support/support.component';
import { HeaderStyleTwoComponent } from './header-style-two/header-style-two.component';
import { HeaderStyleThreeComponent } from './header-style-three/header-style-three.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    TeamComponent,
    FunfactComponent,
    WorksComponent,
    FeedbackComponent,
    PartnerComponent,
    AboutComponent,
    FeaturesComponent,
    FeaturesDetailsComponent,
    ProjectComponent,
    ProjectDetailsComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    HomeTeamComponent,
    BlogComponent,
    GetStartedComponent,
    BlogDetailsComponent,
    ContactComponent,
    PricingComponent,
    TermsComponent,
    PrivacyComponent,
    RefundComponent,
    FaqComponent,
    ComingSoonComponent,
    NotFoundComponent,
    DevelopersComponent,
    WebHostingComponent,
    CustomerFeedbackComponent,
    RepairComponent,
    RepairPartnerComponent,
    IotComponent,
    MachineLearningComponent,
    MachineLearningHome2Component,
    SupportComponent,
    HeaderStyleTwoComponent,
    HeaderStyleThreeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    IconsModule,
    BrowserAnimationsModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    
    Ng2OdometerModule.forRoot(),
    SlickCarouselModule, NgwWowModule
  ],
  providers: [{provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
